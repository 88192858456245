/*
  About
  ------------------------------------------------*/
.about {
    font-family: "Hashed Browns", "sans-serif";
    margin-left: 5%;
    padding-bottom: 5%;
    padding-top: 2.5%;
}

#chibi {
    height: 30%
}

.box {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.box > div {
    padding: 0px;
    margin: 0px;
    max-width: 50%;
}

.about p {
    font-family: "Pumpkin Story", "sans-serif";
    padding: 10px;
}

#doodleson {
    width: 30%;
    height: 30%;
}


@media screen and (max-width:749px) {
    #chibi {
      width: 90%;
    }

    .box {
        padding-left: 0;
    }

    .box > div {
        max-width: 100%;
    }
  }