/*
Colors:

#E8D6CB = Champagne Pink
#D0ADA7 = silver Pink
#AD6A6C = Copper Penny
#5D2E46 = Old mauve
#B58DB6 = Glossy Grape

#DEF2C8 = light tea green
#C5DAC1 = tea green
#A9B2AC = Ashe Gray
#BCD0C7 = opal
#ebaca4 = day color on calendar
*/

/*
Global styles
________________________________________________________
*/
@font-face {
    font-family: "Pumpkin Story";
    src: url(../../public/fonts/pumpkin_story/PumpkinStory.ttf);
}
@font-face {
    font-family: "Hashed Browns";
    src: url(../../public/fonts/hashed_browns/HashedBrowns.ttf);
}
  
  /*
  Welcome ur momadadadzxzxzxzxdddsasaddseweqeddasdsaczczxxzczcxczx - Jeffrey
  ------------------------------------------------*/
.welcome {
    margin: 0%;
    padding: 8% 10% 4%;
    text-align: center;
    color: #a36264;
    background-image: url('../../public/images/collage.png');
    background-attachment: fixed;
    background-size: 100%;
    background-repeat: repeat;
}
.welcome-content {
    background-color: rgba(71, 31, 58, 0.85);
    margin: 1%;
    padding: 2% 5% 2%;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    flex-wrap: wrap;
    border-radius: 3px;
}
  .welcome h1 {
    font-family: "Pumpkin Story", sans-serif;
    font-size: 50px;
  }
  .welcome img {
      width: 20%;
      height: auto;
  }

  .welcome p {
    color: rgb(247, 239, 239);
    font-size: 16px;
  }
  
  /*
  Featured
  ------------------------------------------------*/
  .featured {
    font-family: "Hashed Browns", "sans-serif";
    padding-left: 5%;
    padding-top: 2.5%;
    padding-bottom: 5%;
    padding-right: 5%;
  }
  .featured-item {
    overflow: hidden; /* clear the floating */
  }
  /*
  Calendar info
  ------------------------------------------------*/
  .calendar {
    font-family: "Hashed Browns", "sans-serif";
    padding-left: 5%;
    padding-bottom: 8%;
  }
  .calendar p {
    font-family: "Pumpkin Story", "sans-serif";
    padding: 0%;
  }

  .details {
      width: 70%;
      display:block;
      margin-left: auto;
      margin-right: auto;
      padding-bottom: 30%;
  }

  @media screen and (max-width:900px) {
    .welcome-content {
      width: 90%;
      padding: 8% 8% 8%;
    }

    .welcome p {
      font-size: 10px;
    }

    .welcome {
      padding-top: 15%;
      width: 100%;
    }

    .welcome h1 {
      font-size: 30px;
    }
  }
  