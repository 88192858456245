@font-face {
    font-family: "Pumpkin Story";
    src: url(../../public/fonts/pumpkin_story/PumpkinStory.ttf);
}

@font-face {
    font-family: "Hashed Browns";
    src: url(../../public/fonts/hashed_browns/HashedBrowns.ttf);
}

@font-face {
    font-family: "Poppins";
    src: url(../../public/fonts/Poppins/Poppins-Regular.ttf);
}

/**
    Body
*/
body {
    margin: 0;
    padding: 0;
    font-family: 'Poppins', monospace;
    color: black;
    font-size: 16px;
    line-height: 1.5;
    background-image: url(../../public/images/doodlespace.png);
    background-repeat: repeat;
    background-attachment: fixed;
}

html {
    box-sizing: border-box;
}

*, *:before, *:after {
    box-sizing: inherit;
}

a {
    color: #96dd88;
}

.card-link {
    text-decoration: none;
    color:black
}

    .card-link:hover {
        color: #96dd88
    }

    a:hover {
        text-decoration: none;
        color: #ffffff;
    }

h1 {
    font-size: 60px;
    line-height: 1.75;
}

h2 {
    font-size: 40px;
}

h3 {
    font-size: 25px;
    padding-top: 3px;
    padding-bottom: 3px;
}

h4 {
    font-size: 20px;
}

h1, h2 {
    margin: 0;
    color: #AD6A6C;
}

h1, h2, h3, h4 {
    font-family: "Pumpkin Story";
}

p {
    font-family: 'Poppins';
}

.auto-align {
    max-width: 750px;
    width: 90%;
    margin: 0 auto;
    padding: 15px 0;
}

.m-auto {
    margin-left: auto;
    margin-right: auto;
}

h3 {
    margin-bottom: 0;
    padding-bottom: 0;
}

.divider > section {
    border-bottom: 2px solid #E8D6CB;
    padding: 15px 0;
}

    .divider > section:last-of-type {
        border-bottom: none;
    }

.small-section {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 1%;
    padding-top: 2%;
}

.small-section:first-of-type {
    padding-top: 7%;
}

.small-section:last-of-type {
    padding-bottom: 80px;
}
/*
    Navigation
*/
header {
    background-color: rgb(247, 239, 239);
}

    header a {
        text-decoration: none;
    }

#dango{
    height: 70px;
}

.navigation-bar {
   display: flex;
   justify-content: space-around;
   padding: 0.5% 20% 0.5% 20%; 
   background-color: #471f3a;
   flex: 1 1 0px;
   z-index: 9998;
}
.navigation-item {
    font-family: "Hashed Browns", "serif-sans";
    padding: 1px 8px 1px;
    color: #b9e95b;
    font-size: 20px;
}
.navigation p {
    font-size: 25px;
}

.sticky {
    position: fixed;
    width: 100%;
  }

/**
    Card
**/
.purple-card {
    background-color: #471f3a;
    margin: 15px;
    padding: 0% 3% 0%;
    /*box-shadow: 0 70px;*/
    max-width: 400px;
    text-align: center;
    color: #a36264;
    flex-wrap: wrap;
    flex: 300px;
    border-radius: 15px;
}

.card {
    background-color: white;
    margin: 15px;
    padding: 0% 1% 0%;
    /*box-shadow: 0 70px;*/
    max-width: 400px;
    text-align: center;
    flex-wrap: wrap;
    flex: 300px;
    border-radius: 15px;
    height: 100%;
    color: #471f3a;
}

.card p {
    font-size: 16px;
}

.transparent {
    opacity: 0.75;
}
.transparent:hover {
    opacity: 1;
}

.card ol, .card ul {
    padding-top: 0%;
    margin-top: 0;
}

.card h3, .card h4 {
    padding-bottom: 0;
    margin-bottom: 0;
}

.card h4 {
    margin-top: 0;
}

.card-img {
    width: 100%;
    padding-top: 10%;
}

.card-row {
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
}

.no-wrap {
    flex-wrap: nowrap;
}

.expand {
    cursor: pointer;
}

.collapse {
    cursor: pointer;
}

input, textarea{
    border-radius: 5px;
    border: none;
    padding: 2px;
    font-family: "Poppins";
}

.column {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

/**
    Button
**/
.button-row {
    padding: 0% 2% 0%;
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
}

.button-green {
    flex: 1 1 0px;
    font-family: "Poppins";
    font-size: 20px;
    margin: 15px 5px 15px;
    padding: 15px;
    flex-basis: 40%;
    border: none;
    background-color: #b9e95b;
    cursor: pointer;
    border-radius: 100px;
    color: #471f3a;
}

    .button-green:active {
        background-color: #b9e95b;
    }

    .button-green:hover {
        transform: translateY(-5px);
    }

.button {
    font-family: "Poppins";
    font-size: 20px;
    margin: 2px 5px 15px;
    padding: 15px;
    flex-basis: 40%;
    border: none;
    background-color: rgb(247, 239, 239);
    max-width: 200px;
    cursor: pointer;
    border-radius: 100px;
    color: #471f3a;
}

    .button:active {
        background-color: #8ca388;
    }

    .button:hover {
        transform: translateY(-5px);
    }

    /*
        Tables
    */
table {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    font-size: 20px;
    font-family: "Poppins";
}

table img {
    max-height: 150px;
}

td {
    width: 25%;
}

#sizingtable {
    display: block;
    width: 30%;
    margin-left: auto;
    margin-right: auto;
}

/*

  /*
  Contact info
  -------------------------------------------------*/
footer {
    background-color: rgb(248, 247, 247);
    text-align: center;
}

footer h2 {
    color: #471f3a;
}

.contact-list {
    list-style-type: none;
    display: flex;
    justify-content: center;
    padding: 0% 10% 0% 10%;
}

    .contact-list li {
        padding: 1px 20px 1px;
    }

    .contact-list a {
        font-family: "Poppins";
        font-size: 14px;
        padding: 5px;
        display: inline-block;
        color: #471f3a;
        text-decoration: none; 
    }

.patterns a {
    color: #471f3a;
    font-size: larger;
    text-decoration: underline;
}

/*
Popups
-------------------------------------------------*/
.popup {
    background-color: rgb(247, 239, 239);
    margin: 10px;
    padding: 1%;
    text-align: center;
    border-radius: 15px;
    height: 100%;
    box-shadow: #3d3d3dab;
    font-size: 12px;
    z-index: 9999;
}

.white-bg {
    background-color: white;
}

.max-width-600 {
    max-width: 600px;
}

.popup textarea, .popup input {
    border: solid #471f3a 1px;
    padding: 5px;
    margin: 1px 10px 1px 10px;
    font-size: 11px;
}
.popup-row {   
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: flex-start;
    flex-direction: row;
}
.popup-col {
    margin: 5px;
    padding: 0% 1% 0%;
}

.popup h4, .popup h2 {
    margin: 1px;
    padding: 0px;
}

.popup h2 {
    font-size: 25px;
}

.popup button{
    font-size: 15px;
    padding: 7px;
}

.popup-img {
    width: 90%;
    height: auto;
}

.modal {
    padding-top:10%;
  }
/*
  Responsive
  ----------------------------------------------*/

@media screen and (min-width: 1600px) {
    .navigation-item {
        font-size: 26px;
    }
    #dango {
        height: 80px;
    }

    .popup {
        font-size: 18px;
    }

    .popup h2 {
        font-size: 50px;
    }
}

@media screen and (min-width: 901px) {
    .section {
        padding-left: 100px;
    }
}

@media screen and (max-width:900px) {
    form {
        width:90%;
    }

    #dango{
        height: 30px;
    }

    #footer-img {
        height: 15px;
    }

    .navigation-bar {
        padding: 2% 10% 0.5% 10%;
        justify-content: space-around;
     }

    .navigation-item {
        padding: 1px 1px 1px;
        font-size: 12px;
    }

    h1 {
        font-size: 25px;
    }

    h2 {
        font-size: 23px;
    }

    h3,h4 {
        font-size: 20px;
    }

    p {
        font-size: 10px;
    }

    td, label {
        font-size: 13px;
    }

    table img {
        height: 75px;
    }

    h1, h2 {
        line-height: 1.12;
    }

    .button, .button-green{
        font-size: 12px;
        border-radius: 70px;
        padding: 10px;
        margin: 3px;
    }

    li {
        font-size: 10px;
    }

    .contact-list {
        padding: 0% 5% 0% 5%;
    }

    .contact-list a {
        padding: 2px;
        font-size: 10px;
    }

    .calendar .details {
        width: 100%;
    }

    header {
        font-size: 16px;
    }
    
    .patternAssembly img {
        width: 95%;
    }

    .small-section:first-of-type {
        padding-top: 18%;
    }

    /* Card stuff D: */
    .card, .purple-card {
        padding: 0% 3% 0%;
        /*box-shadow: 0 70px;*/
        width: 90%;
    }
    
    .card p, .purple-card p {
        font-size: 16px;
    }
    
    .transparent {
        opacity: 1;
    }

    .card ol, .card ul {
        padding-top: 0%;
        margin-top: 0;
    }
    
    .card h3, .card h4 {
        padding-bottom: 0;
        margin-bottom: 0;
    }
    
    .card-img {
        width: 100%;
        padding-top: 8%;
    }

    .popup {
        padding: 2% 1% 2%;
    }
    
    .popup-row, .card-row {
        display: flex;
        align-content: center;
        justify-content: space-between;
        align-items: flex-start;
        flex-direction: column;
    }

    .popup h4 {
        font-size: 15px;
    }

    .popup h2 {
        font-size: 18px;
    }
    
    .popup, .popup input, .popup textarea, .popup .button{
        font-size: 8px;
    }

    .popup-col, .popup .button{
        padding: 1px 15px;
    }

    #doodleson {
        width: 60%;
    }
}